import { Box, Grid } from "@mui/material";
import React from "react";
import Heading from "../../components/Heading/Heading";
import GoldMine from "../../assets/Gold-Mine.png";
import ContactSection from "../../components/Contact/Contact";
import SEO from "../../hooks/SEO";
const Contact = () => {
  return (
    <>
      {" "}
      <SEO
        title="HEARD GROUP | INTERNATIONAL FOR MINING"
        description="Heard Group International For Mining Landing Page."
        name="Heard Group."
        type="landing_page"
      />
      <Box sx={{ backgroundColor: "#161616", textAlign: "center" }}>
        <Box>
          <Heading head="Contact Us" img={GoldMine} />
        </Box>
        <Box>
          <Grid container lg={12}>
            <Grid item lg={7}>
              1
            </Grid>
            <Grid item lg={5}>
              <ContactSection />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Contact;
