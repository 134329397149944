import { Box, Button, Divider, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { posts } from "../../data/mockData";
import GoldMine from "../../assets/Gold-Mine.png";
import Heading from "../../components/Heading/Heading";
import CHeader from "../../components/CHeader/CHeader";
import SEO from "../../hooks/SEO";

const Blog = () => {
  return (
    <>
      {" "}
      <SEO
        title="HEARD GROUP | INTERNATIONAL FOR MINING"
        description="Heard Group International For Mining Landing Page."
        name="Heard Group."
        type="landing_page"
      />
      <Box sx={{ backgroundColor: "#161616", textAlign: "center" }}>
        <Box>
          <Heading head="Archives" img={GoldMine} />
        </Box>
        <Box sx={{ paddingTop: "10%", paddingX: "4%" }}>
          <CHeader
            Tag="Our Blog"
            Title="Exploring the World of Mining through Our Insightful Blog!"
            Description="Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nullam bibendum pharetra augue, at tempus ex tincidunt vel. Aenean rutrum auctor leo at suscipit. Curabitur pulvinar libero mi, vitae tristique eros viverra non."
          />
        </Box>
        <Box sx={{ width: "100%", paddingY: "10%" }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ paddingX: "5%" }}
          >
            {posts.map((post) => {
              return (
                <Grid item lg={3} sx={{}}>
                  <Paper key={post._id} elevation={7} sx={{}}>
                    <Box>
                      <Box
                        component="img"
                        src={post.cover}
                        sx={{ width: "100%", height: "100%" }}
                      />
                    </Box>
                    <Divider />
                    <Box sx={{ margin: "20px", textAlign: "left" }}>
                      <Box sx={{ paddingY: "10px" }}>
                        <Typography
                          variant="h5"
                          fontWeight={700}
                          textAlign="left"
                        >
                          {post.title}
                        </Typography>
                      </Box>
                      <Box sx={{ paddingY: "10px" }}>
                        {" "}
                        <Typography
                          variant="p"
                          fontWeight={500}
                          textAlign="left"
                        >
                          {post.short}
                        </Typography>
                      </Box>
                      <Box sx={{ marginY: "20px", paddingBottom: "20px" }}>
                        <Button sx={{ color: "#ED7D31", fontWeight: "700" }}>
                          Read More
                        </Button>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Blog;
