import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import "./App.css";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import React from "react";
function App() {
  return (
    <>
      <div className="App">
        <HelmetProvider>
          <BrowserRouter>
            <ScrollToTop />
            <Router />
          </BrowserRouter>
        </HelmetProvider>
      </div>
      ;
    </>
  );
}

export default App;
