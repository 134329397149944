import { Box, Breadcrumbs, Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const Heading = (props) => {
  const { head, img } = props;
  const Navigate = useNavigate();
  function handleClick(event) {
    event.preventDefault();
    Navigate("/");
  }
  const breadcrumbs = [
    <Link
      underline="hover"
      key="2"
      href="/"
      onClick={handleClick}
      style={{ color: "#ED7D31", fontSize: "20px" }}
    >
      Home
    </Link>,
    <Typography key="3" sx={{ color: "#fff", fontSize: "20px" }}>
      {head}
    </Typography>,
  ];
  return (
    <>
      <Box>
        <Box
          sx={{
            height: { xl: "35vh", lg: "", xs: "" },
            objectFit: "cover",
            backgroundPosition: " center",
            backgroundRepeat: "repeat",
            backgroundImage: `url(${img})`,
            backgroundAttachment: "fixed",
            overflow: "hidden",
            textAlign: "center",
            position: "relative",
            backdropFilter: "blur(250px)",
          }}
        >
          <Box sx={{ paddingY: "5%" }}>
            <Typography
              variant="p"
              sx={{
                fontWeight: "Bold",
                fontSize: "3.4rem",
                textAlign: "center",
                color: "#fff",
              }}
            >
              {head}
            </Typography>
            <Box
              sx={{
                textAlign: "center",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                justifyItems: "center",
                position: "relative",
                display: "flex",
                paddingY: "20px",
              }}
            >
              <Stack spacing={2} sx={{ textAlign: "center" }}>
                <Breadcrumbs
                  separator=">"
                  sx={{ color: "#fff" }}
                  aria-label="breadcrumb"
                >
                  {breadcrumbs}
                </Breadcrumbs>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Heading;
