import { Box, Typography } from "@mui/material";
import React from "react";

const CHeader = (props) => {
  const { Tag, Title, Description } = props;
  return (
    <>
      <Box>
        <Box sx={{ paddingBottom: "30px" }}>
          <Typography
            variant="p"
            sx={{
              fontSize: "20px",
              fontWeight: "700",
              color: "#ED7D31",
            }}
          >
            {Tag}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="p"
            sx={{
              fontSize: "80px",
              fontWeight: "700",
              lineHeight: "1.1em",
              color: "#fff",
            }}
          >
            {Title}
          </Typography>
        </Box>
        <Box>
          <Box
            sx={{
              paddingTop: "50px",
              paddingBottom: "20px",
              paddingX: "20%",
            }}
          >
            <Typography
              variant="p"
              sx={{ fontSize: "20px", fontWeight: "400", color: "#fff" }}
            >
              {Description}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CHeader;
