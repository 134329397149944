import React from "react";
import SEO from "../../hooks/SEO";

const PostDetails = () => {
  return (
    <>
      {" "}
      <SEO
        title="HEARD GROUP | INTERNATIONAL FOR MINING"
        description="Heard Group International For Mining Landing Page."
        name="Heard Group."
        type="landing_page"
      />
    </>
  );
};

export default PostDetails;
